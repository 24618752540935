<template>
    <div id="menuIterm">
        <template v-for="menu in userMenus">
            <!-- 最后一级菜单 prop:是否在新窗口中打开 show:是否显示在菜单项中-->
            <router-link v-if="!menu.children && menu.meta.prop==1 && menu.meta.show==0" to="" @click.native="toPage(menu.page,menu.meta.prop,menu.meta.type)" :key="menu.id">
                <el-menu-item :index="menu.id">
                    <i :class="menu.meta.icon" style="font-size: 14px"/>
                    <span>{{menu.meta.title}}</span>
                </el-menu-item>
            </router-link>

            <!-- 最后一级菜单 prop:是否在新窗口中打开 show:是否显示在菜单项中-->
            <router-link v-if="!menu.children && menu.meta.prop==0 && menu.meta.show==0" :to="menu.path" :key="menu.id">
                <el-menu-item :index="menu.id">
                    <i :class="menu.meta.icon" style="font-size: 14px"/>
                    <span>{{menu.meta.title}}</span>
                </el-menu-item>
            </router-link>



            <!-- 此菜单下还有子菜单 show:是否显示在菜单项中-->
            <el-submenu v-if="menu.children && menu.meta.show==0" :key="menu.id" :index="menu.id">
                <template #title>
                    <i :class="menu.meta.icon" style="font-size: 14px"/>
                    <span>{{menu.meta.title}}</span>
                </template>
                <!-- 递归 -->
                <MenuIterm :userMenus="menu.children"/>
            </el-submenu>
        </template>
    </div>
</template>

<script>
    import { ref,reactive,toRefs,onMounted,computed,getCurrentInstance,defineComponent} from 'vue';
    export default defineComponent({
        name: "MenuIterm",
        props: ["userMenus"],
        setup(props){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                userMenus:props.userMenus
            })
            const toPage=(page,prop,type)=>{
                const userToken = sessionStorage.getItem(utils.$$const.baseVar.jfAccessToken);
                if(1==type){
                    window.open(page+'?token='+userToken);
                }else{
                    window.open(page);
                }
            }
            return {
                ...toRefs(dataObj),toPage
            }
        },
        components: {}
    });
</script>

<style scoped>

    .el-menu--collapse span,
    .el-menu--collapse i.el-submenu__icon-arrow {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline-block;
    }

</style>
